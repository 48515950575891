import { GiRoundStar } from 'react-icons/gi';
import './Story.css';

const JunkCollector = () => {
  return (
    <div className="story-container">
      <div className="story-row">
        1 <GiRoundStar color="orange" size={15} className="story-star" /> for
        each card with a different perk (max 5)
      </div>
    </div>
  );
};

export default JunkCollector;
