import { ChangeEvent, Fragment, useState } from 'react';
import CardData from '../../data/cards.json';
import IconData from '../../data/icons.json';
import cardNormalizer from '../../utils/cardNormalizer';
import iconNormalizer from '../../utils/iconNormalizer';
import IconContainer from '../IconContainer/IconContainer';

const Statistics = () => {
  const [onlyWithCharacters, setOnlyWithCharacters] = useState<boolean>(false);
  const cards = CardData.map((row) => cardNormalizer(row));
  const icons = IconData.map((row) => iconNormalizer(row));

  const getFilteredCards = () =>
    onlyWithCharacters
      ? cards.filter(
          (card) => card && card.characters && card.characters.length
        )
      : cards;

  const getCraftingCount = (icon: string) => {
    const count = getFilteredCards().reduce(
      (sum, card) =>
        sum + (card?.upgrade?.icons.filter((i) => i === icon).length || 0),
      0
    );
    return count;
  };

  const getIconCount = (icon: string) => {
    const count = getFilteredCards().filter((card) =>
      card?.icons.includes(icon)
    );
    return count.length;
  };

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    setOnlyWithCharacters(e.target.checked);
  };

  return (
    <Fragment>
      <label htmlFor="onlyWithUpgrade">Only with characters?</label>
      <input
        type="checkbox"
        id="onlyWithCharacters"
        onChange={handleChangeValue}
      />
      <table className="border-collapse border border-black border-spacing-5">
        <thead>
          <th className="border border-black">Icon</th>
          <th className="border border-black">Icon Name</th>
          <th className="border border-black">Amount Given</th>
          <th className="border border-black">Amount Crafting</th>
        </thead>
        <tbody>
          {icons.map((icon, key) =>
            icon ? (
              <tr key={key}>
                <td className="border border-black text-center ">
                  <IconContainer icon={icon} />
                </td>
                <td className="border border-black text-center">{icon.id}</td>
                <td className="border border-black text-center">
                  {getIconCount(icon.id)}
                </td>
                <td className="border border-black text-center">
                  {getCraftingCount(icon.id)}
                </td>
              </tr>
            ) : null
          )}
        </tbody>
      </table>
    </Fragment>
  );
};

export default Statistics;
