import { Fragment, useState } from 'react';
import Data from '../data/cards.json';
import cardNormalizer from '../utils/cardNormalizer';
import Filters from '../types/Filters.interface';
import Card from '../types/Card.interface';
import Settings from './Settings/Settings';
import { isCustomStory, isGeneralStory } from '../types/Story.interface';
import CardsWrapper from './CardsWrapper/CardsWrapper';

const Layouter = () => {
  const [filters, setFilters] = useState<Filters>({
    icons: [],
    idMax: null,
    idMin: null,
    characterId: null,
    onlyWithCharacters: false,
    onlyWithOrigin: false,
    onlyWithUpgrade: false,
    showItems: true,
    showStories: true,
    set: 0,
  });

  const handleChangeFilters = (filters: Filters) => setFilters(filters);

  const filterCards = (data: any): Card[] => {
    const multiplier = null;

    return data
      .map((row: any) => cardNormalizer(row))
      .filter((card: Card, key: number) => {
        const story = card.story;
        return (
          (!filters.onlyWithUpgrade ||
            (filters.onlyWithUpgrade && card.upgrade)) &&
          (!filters.onlyWithCharacters ||
            (filters.onlyWithCharacters &&
              card.characters &&
              card.characters.length)) &&
          (!filters.onlyWithOrigin ||
            (filters.onlyWithOrigin && card.origin)) &&
          (!filters.idMin ||
            filters.idMin === 0 ||
            (filters.idMin && card.id >= filters.idMin)) &&
          (!filters.idMax ||
            filters.idMax === 0 ||
            (filters.idMax && card.id <= filters.idMax)) &&
          (!filters.characterId ||
            (filters.characterId &&
              (card.characters?.includes(filters.characterId) ||
                card.icons?.includes(filters.characterId)))) &&
          (filters.icons.length === 0 ||
            card.icons.some((icon) => filters.icons.includes(icon)) ||
            card.upgrade?.icons.some((icon) => filters.icons.includes(icon)) ||
            (story &&
              ((isGeneralStory(story) &&
                story.rules.some((rule) =>
                  Array.isArray(rule.icons)
                    ? filters.icons.filter((icon) =>
                        (rule.icons as Array<string | number>).includes(icon)
                      )
                    : filters.icons.includes(rule.icons)
                )) ||
                (isCustomStory(story) &&
                  story.icons?.some((icon) =>
                    filters.icons.includes(icon)
                  ))))) &&
          (filters.showItems || !!card.story) &&
          (filters.showStories || !card.story)
        );
      })
      .filter(
        (_: any, key: number) =>
          !multiplier ||
          (key >= filters.set * multiplier &&
            key < filters.set * multiplier + multiplier)
      );
  };

  return (
    <Fragment>
      <Settings filters={filters} onChange={handleChangeFilters} />
      <CardsWrapper cards={filterCards(Data)} />
    </Fragment>
  );
};

export default Layouter;
