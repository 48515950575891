import { CustomStory } from '../types/Story.interface';
import Captain from './Captain';
import Collector from './Collector';
import Craftsman from './Craftsman';
import Dreamer from './Dreamer';
import Etherial from './Etherial';
import JunkCollector from './JunkCollector';
import Millionaire from './Millionaire';
import Pilgrim from './Pilgrim';
import Promoter from './Promoter';
import Religious from './Religious';
import Scientist from './Scientist';
import Secretary from './Secretary';
import Sheikh from './Sheikh';
import Simple from './Simple';
import Storyteller from './Storyteller';
import TreasureHunter from './TreasureHunter';

const CustomStoryComponent = ({ story }: { story: CustomStory }) => {
  if (story.name === 'captain') {
    return <Captain />;
  }

  if (story.name === 'collector') {
    return <Collector />;
  }

  if (story.name === 'craftsman') {
    return <Craftsman />;
  }

  if (story.name === 'etherial') {
    return <Etherial />;
  }

  if (story.name === 'junk') {
    return <JunkCollector />;
  }

  if (story.name === 'religious') {
    return <Religious />;
  }

  if (story.name === 'scientist') {
    return <Scientist />;
  }

  if (story.name === 'simple') {
    return <Simple />;
  }

  if (story.name === 'storyteller') {
    return <Storyteller />;
  }

  if (story.name === 'treasure') {
    return <TreasureHunter />;
  }

  if (story.name === 'trader') {
    return <Promoter />;
  }

  if (story.name === 'secretary') {
    return <Secretary />;
  }

  if (story.name === 'sheikh') {
    return <Sheikh />;
  }

  if (story.name === 'dreamer') {
    return <Dreamer />;
  }

  if (story.name === 'pilgrim') {
    return <Pilgrim />;
  }

  if (story.name === 'millionaire') {
    return <Millionaire />;
  }

  return <div>ERROR: NO STORY</div>;
};

export default CustomStoryComponent;
