import { FishToImage } from '../../assets/icons';
import IconInterface from '../../types/Icon.interface';
import './Icon.css';

const Icon = ({
  icon,
  onClick,
}: {
  icon: IconInterface;
  onClick?: (icon: IconInterface) => void;
}) => {
  const getSize = () => {
    if (icon.size === 'lg') {
      return '0.8cm';
    }
    if (icon.size === 'sm') {
      return '0.4cm';
    }
    return '0.5cm';
  };

  const handleClick = () => {
    if (!onClick) {
      return null;
    }
    onClick(icon);
  };

  return (
    <img
      alt={icon.id}
      className="card-icon"
      src={FishToImage.get(icon.icon)}
      style={{
        filter: icon.color === 'active' ? 'brightness(70%)' : undefined,
        width: getSize(),
      }}
      onClick={handleClick}
    />
  );
};

export default Icon;
