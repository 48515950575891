import { GiRoundStar } from 'react-icons/gi';
import './Story.css';
import IconContainer from '../components/IconContainer/IconContainer';

const Pilgrim = () => {
  return (
    <div className="story-container">
      <div className="story-row">
        1 <GiRoundStar color="orange" size={15} className="story-star" /> for
        each card with an origin (max 3)
      </div>
      <div className="story-row">
        1 <GiRoundStar color="orange" size={15} className="story-star" /> for if
        you have at least 1
        <div className="story-icon-wrapper">
          <IconContainer icon="jewelery" size="sm" />
        </div>
      </div>
      <div className="story-row">
        1 <GiRoundStar color="orange" size={15} className="story-star" /> for if
        you have at least 1
        <div className="story-icon-wrapper">
          <IconContainer icon="food" size="sm" />
        </div>
      </div>
    </div>
  );
};

export default Pilgrim;
