import { Fragment } from 'react';
import Layouter from './components/Layouter';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import CharactersWrapper from './components/CharactersWrapper/CharactersWrapper';
import Statistics from './components/Statistics/Statistics';
import Detail from './components/Detail/Detail';

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layouter />,
    },
    {
      path: 'character',
      element: <CharactersWrapper />,
    },
    {
      path: 'statistics',
      element: <Statistics />,
    },
    {
      path: 'detail/:id',
      element: <Detail />,
    },
  ]);

  return (
    <Fragment>
      <a href={`/`}>Home</a> | <a href={`/character`}>Characters</a> |{' '}
      <a href={`/statistics`}>Statistics</a>
      <RouterProvider router={router} />
    </Fragment>
  );
}

export default App;
