import { Fragment, useRef } from 'react';
import Card from '../../types/Card.interface';
import CardComponent from '../Card/Card';
import StoryCardComponent from '../StoryCard/StoryCard';
import { useReactToPrint } from 'react-to-print';

const CardsWrapper = ({
  cards,
  hideButton,
}: {
  cards: Card[];
  hideButton?: boolean;
}) => {
  const handlePrint = useReactToPrint({
    content: () => componentRef.current || null,
  });

  const componentRef = useRef(null);

  return (
    <Fragment>
      {!hideButton && (
        <button
          onClick={handlePrint}
          className="font-bold py-2 px-4 bg-blue-500 text-white w-full"
        >
          Print {cards.length}
        </button>
      )}
      <div className="flex flex-wrap gap-2 m-8" ref={componentRef}>
        {cards.map((card, key) => {
          if (card.story) {
            return <StoryCardComponent card={card} key={key} />;
          }
          return <CardComponent card={card} key={key} />;
        })}
      </div>
    </Fragment>
  );
};

export default CardsWrapper;
