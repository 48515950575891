import agatha from './agatha.png';
import ben from './ben.png';
import benedict from './benedict.png';
import brad from './brad.png';
import bruce from './bruce.png';
import christian from './christian.png';
import cilian from './cilian.png';
import david from './david.png';
import dwayne from './dwayne.png';
import edward from './edward.png';
import emma from './emma.png';
import flash from './flash.png';
import helena from './helena.png';
import hugh from './hugh.png';
import jackie from './jackie.png';
import jennifer from './jennifer.png';
import johnny from './johnny.png';
import reanu from './reanu.png';
import lin from './lin.png';
import peter from './peter.png';
import pedro from './pedro.png';
import pippi from './pippi.png';
import roberts from './roberts.png';
import samuel from './samuel.png';
import steve from './steve.png';
import tom from './tom.png';
import vin from './vin.png';
import whoopi from './whoopi.png';
import will from './will.png';
import witcher from './witcher.png';
import yennefer from './yennefer.png';

export const CharacterToImage = new Map<string, string>([
  ['agatha', agatha],
  ['ben', ben],
  ['benedict', benedict],
  ['brad', brad],
  ['bruce', bruce],
  ['christian', christian],
  ['cilian', cilian],
  ['david', david],
  ['dwayne', dwayne],
  ['edward', edward],
  ['emma', emma],
  ['flash', flash],
  ['helena', helena],
  ['hugh', hugh],
  ['jackie', jackie],
  ['jennifer', jennifer],
  ['johnny', johnny],
  ['lin', lin],
  ['pedro', pedro],
  ['peter', peter],
  ['pippi', pippi],
  ['reanu', reanu],
  ['roberts', roberts],
  ['samuel', samuel],
  ['steve', steve],
  ['tom', tom],
  ['vin', vin],
  ['whoopi', whoopi],
  ['will', will],
  ['witcher', witcher],
  ['yennefer', yennefer],
]);
