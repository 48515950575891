import { ReactNode } from 'react';
import './Border.css';
import border_item from '../../assets/border_2.png';
import border_story from '../../assets/border_3.png';

const Border = ({
  children,
  type,
}: {
  children?: ReactNode;
  type?: 'item' | 'story';
}) => {
  const getBorder = () => {
    if (type === 'story') {
      return border_story;
    }

    return border_item;
  };

  return (
    <div
      className="card-border"
      style={{ backgroundImage: `url(${getBorder()})` }}
    >
      {children}
    </div>
  );
};

export default Border;
