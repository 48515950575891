import Card from '../../types/Card.interface';
import Border from '../Border/Border';
import Title from '../Title/Title';
import IconWrapper from '../IconWrapper/IconWrapper';
import IconContainer from '../IconContainer/IconContainer';
import './Card.css';
import Origin from '../Origin/Origin';
import UpgradeWrapper from '../UpgradeWrapper/UpgradeWrapper';
import useImage from '../../utils/useImage';

const CardComponent = ({ card }: { card?: Card }) => {
  const { loading, error, image } = useImage(card?.image);

  if (loading) {
    return <p>LOADING</p>;
  }

  if (!card || error) {
    return <p>{card?.image}</p>;
  }

  return (
    <div
      className="card-container"
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <Border>
        {card.origin && (
          <Origin origin={card.origin.name} dark={card.origin.dark} />
        )}
        <IconWrapper>
          {card.icons.map((icon, key) => (
            <IconContainer icon={icon} key={key} />
          ))}
        </IconWrapper>
        {card.upgrade && <UpgradeWrapper upgrade={card.upgrade} />}
        <Title title={card.name} />
      </Border>
    </div>
  );
};

export default CardComponent;
