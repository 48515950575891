import { GiRoundStar } from 'react-icons/gi';
import './Story.css';
import IconContainer from '../components/IconContainer/IconContainer';

const Captain = () => {
  return (
    <div className="story-container">
      <div className="story-row">
        1 <GiRoundStar color="orange" size={15} className="story-star" /> for
        each{' '}
        <div className="story-icon-wrapper">
          <IconContainer icon="ship" size="sm" />
        </div>{' '}
        (max 3)
      </div>
      <div className="story-row">
        1 <GiRoundStar color="orange" size={15} className="story-star" /> for
        each manual course change (max 3)
      </div>
    </div>
  );
};

export default Captain;
