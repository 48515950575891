import Character from '../types/Character.interface';

function characterNormalizer(rawData: any): Character | undefined {
  if (typeof rawData !== 'object' || !rawData.id) {
    return undefined;
  }

  return {
    id: rawData.id || 0,
    name: rawData.name || '',
    role: rawData.role || '',
    image: rawData.image || '',
    story: rawData.story,
    skills: rawData.skills,
    faction: rawData.faction,
  };
}

export default characterNormalizer;
