import './Title.css';

const Title = ({
  title,
  type = 'item',
}: {
  title: string;
  type?: 'item' | 'story';
}) => {
  const getStyle = () => {
    if (type === 'story') {
      return { bottom: '2.2cm', fontSize: '0.5cm' };
    }
    return { bottom: '0.05cm' };
  };

  return (
    <div className="font-semibold text-center card-title" style={getStyle()}>
      {title}
    </div>
  );
};

export default Title;
