import { Fragment } from 'react';
import getFaction from '../../utils/getFaction';
import IconContainer from '../IconContainer/IconContainer';
import { GiRoundStar } from 'react-icons/gi';

const Faction = ({ faction }: { faction: string }) => {
  const factionData = getFaction(faction);

  if (faction === 'traveler') {
    return (
      <Fragment>
        <span>: </span>
        <div className="text-center">{factionData.name}</div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <span>: {factionData.name}</span>
      <div className="text-center">
        Add all
        <span className="story-icon-wrapper">
          <IconContainer icon={factionData.wantedIcon} size={'sm'} />
        </span>
        within your faction.
        {factionData.levels.map((level, key) => (
          <div key={`faction_level_${key}`}>
            {key + 1}{' '}
            <GiRoundStar color="orange" size={15} className="story-star" /> if
            you have {level}
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default Faction;
