import { Link, useParams } from 'react-router-dom';
import CharactersData from '../../data/characters.json';
import CardsData from '../../data/cards.json';
import characterNormalizer from '../../utils/characterNormalizer';
import Character from '../Character/Character';
import { Fragment } from 'react';
import cardNormalizer from '../../utils/cardNormalizer';
import CardsWrapper from '../CardsWrapper/CardsWrapper';
import Card from '../../types/Card.interface';

const Detail = () => {
  let { id } = useParams();

  if (!id) {
    return null;
  }

  const parsedId = parseInt(id);

  const character = CharactersData.map((row) => characterNormalizer(row)).find(
    (row) => row?.id === parsedId
  );

  const cards = CardsData.map((row) => cardNormalizer(row)).filter(
    (row) =>
      !!row &&
      (row?.icons.includes(parsedId) || row?.characters?.includes(parsedId))
  ) as Card[];

  if (!character) {
    return null;
  }

  const nextId = parsedId < 31 ? parsedId + 1 : 1;

  return (
    <div>
      <span>ID: {parsedId} | </span>
      <Link to={`/detail/${nextId}`}>
        <button className="bg-blue-500 px-4 rounded">{nextId}</button>
      </Link>
      <div className="flex">
        <Character character={character} />
        <CardsWrapper cards={cards} hideButton={true} />
      </div>
    </div>
  );
};

export default Detail;
