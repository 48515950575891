import archeologist from './archeologist.png';
import artist from './artist.png';
import banker from './banker.png';
import believer from './believer.png';
import blacksmith from './blacksmith.png';
import brute from './brute.png';
import collector from './collector.png';
import craftsman from './craftsman.png';
import dreamer from './dreamer.png';
import electrician from './electrician.png';
import etherial from './etherial.png';
import fashioned from './fashioned.png';
import fisherman from './fisherman.png';
import frozen from './frozen.png';
import gatherer from './gatherer.png';
import glamourous from './glamourous.png';
import hungry from './hungry.png';
import industrialist from './industrialist.png';
import junk from './junk.png';
import librarian from './librarian.png';
import leader from './leader.png';
import lovers from './lovers2.png';
import millionaire from './millionaire.png';
import naturalist from './naturalist.png';
import occult from './occult.png';
import reaper from './reaper.png';
import religious from './religious.png';
import rich from './rich.png';
import scientist from './scientist.png';
import secretary from './secretary.png';
import sheikh from './sheikh.png';
import sick from './sick.png';
import simple from './simple.png';
import sleepy from './sleepy.png';
import storyteller from './storyteller.png';
import suspect from './suspect.png';
import trader from './trader.png';
import treasure from './treasure.png';

export const StoryToImage = new Map<string, string>([
  ['archeologist', archeologist],
  ['artist', artist],
  ['banker', banker],
  ['believer', believer],
  ['blacksmith', blacksmith],
  ['brute', brute],
  ['collector', collector],
  ['craftsman', craftsman],
  ['electrician', electrician],
  ['etherial', etherial],
  ['dreamer', dreamer],
  ['fashioned', fashioned],
  ['fisherman', fisherman],
  ['frozen', frozen],
  ['gatherer', gatherer],
  ['glamourous', glamourous],
  ['hungry', hungry],
  ['industrialist', industrialist],
  ['junk', junk],
  ['leader', leader],
  ['librarian', librarian],
  ['lovers', lovers],
  ['millionaire', millionaire],
  ['naturalist', naturalist],
  ['occult', occult],
  ['reaper', reaper],
  ['religious', religious],
  ['rich', rich],
  ['scientist', scientist],
  ['secretary', secretary],
  ['sheikh', sheikh],
  ['sick', sick],
  ['simple', simple],
  ['sleepy', sleepy],
  ['storyteller', storyteller],
  ['suspect', suspect],
  ['trader', trader],
  ['treasure', treasure],
]);
