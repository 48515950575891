import { GiRoundStar } from 'react-icons/gi';
import IconContainer from '../components/IconContainer/IconContainer';
import './Story.css';
import { getIcons } from '../utils/getIcons';

const Dreamer = () => {
  const icons = getIcons([
    'resource-science',
    'resource-coins',
    'resource-crystals',
    'resource-crate',
    'resource-electricity',
  ]);

  if (icons.length < 5) {
    return <div>'NO ICON'</div>;
  }

  return (
    <div className="story-container">
      <div className="story-row">
        5
        <GiRoundStar color="orange" size={15} className="story-star" />
        if you have at least 1 of each
        <div className="story-icon-wrapper">
          <IconContainer icon={{ ...icons[0], size: 'sm' }} />
        </div>
        <div className="story-icon-wrapper">
          <IconContainer icon={{ ...icons[1], size: 'sm' }} />
        </div>
        <div className="story-icon-wrapper">
          <IconContainer icon={{ ...icons[2], size: 'sm' }} />
        </div>
        <div className="story-icon-wrapper">
          <IconContainer icon={{ ...icons[3], size: 'sm' }} />
        </div>
        <div className="story-icon-wrapper">
          <IconContainer icon={{ ...icons[4], size: 'sm' }} />
        </div>
      </div>
    </div>
  );
};

export default Dreamer;
