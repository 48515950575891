import { GiRoundStar } from 'react-icons/gi';
import IconContainer from '../components/IconContainer/IconContainer';
import './Story.css';
import { getIcons } from '../utils/getIcons';

const Religious = () => {
  const icons = getIcons(['resource-crystals']);

  if (!icons.length) {
    return <div>'NO ICON'</div>;
  }

  return (
    <div className="story-container">
      <div className="story-row">
        4
        <GiRoundStar color="orange" size={15} className="story-star" />
        if you have at least 4
        <div className="story-icon-wrapper">
          <IconContainer icon={{ ...icons[0], size: 'sm' }} />
        </div>
      </div>
    </div>
  );
};

export default Religious;
