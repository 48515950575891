import Faction from '../types/Faction.interface';

const getFaction = (id: string): Faction => {
  if (id === 'crew') {
    return {
      id: 'crew',
      name: 'The Crew',
      wantedIcon: 'resource-coins',
      levels: [14, 18, 27, 36],
    };
  }
  if (id === 'guild') {
    return {
      id: 'guild',
      name: 'The Guild',
      wantedIcon: 'resource-science',
      levels: [18, 24, 36, 48],
    };
  }
  if (id === 'cult') {
    return {
      id: 'cult',
      name: 'The Cult',
      wantedIcon: 'resource-crate',
      levels: [18, 24, 36, 48],
    };
  }
  if (id === 'society') {
    return {
      id: 'society',
      name: 'The Society',
      wantedIcon: 'resource-crystals',
      levels: [18, 24, 36, 48],
    };
  }

  return {
    id: 'traveler',
    name: 'You start without a faction. Until day 3, you have to decide which faction you want to join. Your icons will not count toward the faction goals but you will get the same reward as the rest of your faction.',
    wantedIcon: 'resource-crystals',
    levels: [18, 24, 36, 48],
  };
};

export default getFaction;
