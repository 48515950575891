import Upgrade from '../types/Upgrade.interface';

function upgradeNormalizer(rawData: any): Upgrade | undefined {
  if (typeof rawData !== 'object') {
    return undefined;
  }

  return {
    level: rawData.level || '',
    icons: rawData.icons || [],
    to: rawData.to,
  };
}

export default upgradeNormalizer;
