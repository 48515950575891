import IconInterface from '../types/Icon.interface';

function iconNormalizer(rawData: any): IconInterface | undefined {
  if (typeof rawData !== 'object' || !rawData.id) {
    return undefined;
  }

  return {
    id: rawData.id,
    color: rawData.color || '#000',
    icon: rawData.icon,
    image: rawData.image || '',
    size: rawData.size || 'md',
  };
}

export default iconNormalizer;
