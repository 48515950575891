import './IconContainer.css';
import IconInterface from '../../types/Icon.interface';
import { getIconSize } from '../../utils/getIconSize';
import Icon from '../Icon/Icon';
import { getIcon } from '../../utils/getIcons';

interface Props {
  icon: string | number | IconInterface;
  size?: string;
}

const IconContainer = ({ icon, size }: Props) => {
  if (typeof icon === 'number' || icon === '?') {
    return (
      <div
        className="card-icon-container"
        style={{
          backgroundColor: '#111',
          color: '#fff',
          fontSize: size === 'sm' ? '0.3cm' : undefined,
          ...getIconSize(size || 'md'),
        }}
      >
        #{icon}
      </div>
    );
  }

  const iconObject = typeof icon !== 'string' ? icon : getIcon(icon);

  if (!iconObject) {
    return null;
  }

  iconObject.size = size || iconObject.size;

  return (
    <div
      className={`card-icon-container ${
        iconObject.color === 'gradient' ? 'conic-gradient' : undefined
      }`}
      style={{
        backgroundColor:
          iconObject.color !== 'gradient' ? iconObject.color : undefined,
        ...getIconSize(iconObject.size),
      }}
    >
      <Icon icon={iconObject} />
    </div>
  );
};

export default IconContainer;
