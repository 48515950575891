import { GiRoundStar } from 'react-icons/gi';
import './Story.css';

const Storyteller = () => {
  return (
    <div className="story-container">
      <div className="story-row">
        2
        <GiRoundStar color="orange" size={15} className="story-star" />
        for each story card (including this)
      </div>
    </div>
  );
};

export default Storyteller;
