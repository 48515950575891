import { IconContext } from 'react-icons';
import {
  StoryOrCustomStory,
  isCustomStory,
  isGeneralStory,
} from '../../types/Story.interface';
import GeneralStory from '../../stories/GeneralStory';
import CustomStory from '../../stories/CustomStory';

const StoryComponent = ({
  story,
  size,
}: {
  story: StoryOrCustomStory;
  size?: string;
}) => {
  const getStory = () => {
    if (story && isGeneralStory(story)) {
      return <GeneralStory story={story} size={size} />;
    }

    if (story && isCustomStory(story)) {
      return <CustomStory story={story} />;
    }

    return <span>ERROR: NO STORY</span>;
  };

  return (
    <IconContext.Provider
      value={{
        style: {
          display: 'inline-flex',
          justifyContent: 'center',
          verticalAlign: 'text-bottom',
        },
      }}
    >
      {getStory()}
    </IconContext.Provider>
  );
};

export default StoryComponent;
