import { GiRoundStar } from 'react-icons/gi';
import IconContainer from '../components/IconContainer/IconContainer';
import './Story.css';

const Collector = () => {
  return (
    <div className="story-container">
      <div className="story-row">
        1 <GiRoundStar color="orange" size={15} className="story-star" /> for
        each card with level 2 or higher (max 3)
      </div>
      <div className="story-row">
        1
        <GiRoundStar color="orange" size={15} className="story-star" />
        if you have at least 1
        <div className="story-icon-wrapper">
          <IconContainer icon="ship" size="sm" />
        </div>{' '}
      </div>
      <div className="story-row">
        1
        <GiRoundStar color="orange" size={15} className="story-star" />
        if you have at least 1
        <div className="story-icon-wrapper">
          <IconContainer icon="artifact" size="sm" />
        </div>{' '}
      </div>
    </div>
  );
};

export default Collector;
