import Card from '../../types/Card.interface';
import Border from '../Border/Border';
import Title from '../Title/Title';
import './StoryCard.css';
import { StoryToImage } from '../../assets/story';
import StoryComponent from '../Story/StoryComponent';

const StoryCardComponent = ({ card }: { card?: Card }) => {
  if (!card) {
    return null;
  }

  return (
    <div
      className="story-card-container"
      style={{ backgroundImage: `url(${StoryToImage.get(card.image)})` }}
    >
      <Border type="story">
        <Title title={card.name} type="story" />

        <div className="card-story">
          {card.story ? <StoryComponent story={card.story} /> : 'NO STORY'}
        </div>
      </Border>
    </div>
  );
};

export default StoryCardComponent;
