import IconInterface from '../types/Icon.interface';
import IconsData from '../data/icons.json';
import iconNormalizer from './iconNormalizer';

export const getIcon = (iconId: string) => {
  const icon = IconsData.find((icon) => icon.id === iconId);
  if (!icon) {
    return undefined;
  }
  return iconNormalizer(icon);
};

export const getIcons = (iconIds: string[]) => {
  const icons: IconInterface[] = [];

  iconIds.forEach((iconId) => {
    const icon = IconsData.find((icon) => icon.id === iconId);
    if (icon) {
      const normalizedIcon = iconNormalizer(icon);

      if (normalizedIcon) {
        icons.push(normalizedIcon);
      }
    }
  });

  return icons;
};
