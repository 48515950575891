import Card from '../types/Card.interface';
import upgradeNormalizer from './upgradeNormalizer';

function cardNormalizer(rawData: any): Card | undefined {
  if (typeof rawData !== 'object' || !rawData.id) {
    return undefined;
  }

  return {
    id: rawData.id || '',
    name: rawData.name || '',
    icons: rawData.icons || [],
    image: rawData.image || '',
    origin: rawData.origin || undefined,
    story: rawData.story,
    upgrade: upgradeNormalizer(rawData.upgrade),
    characters: rawData.characters,
  };
}

export default cardNormalizer;
