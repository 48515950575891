import { CharacterToImage } from '../../assets/characters';
import './Character.css';
import Diary from '../Diary/Diary';
import CharacterInterface from '../../types/Character.interface';
import StoryComponent from '../Story/StoryComponent';
import Faction from '../Faction/Faction';
import { Link } from 'react-router-dom';
import { GiBuyCard, GiCardPlay, GiUpCard } from 'react-icons/gi';

const Character = ({ character }: { character: CharacterInterface }) => {
  return (
    <div className="character-sheet">
      <div>
        <div className="character-sheet-header">
          <img src={CharacterToImage.get(character.image)} />
          <div className="character-sheet-title-wrapper">
            <Link to={`/detail/${character.id}`}>#{character.id}</Link>
            <div className="character-sheet-title">{character.name}</div>
            <div className="character-sheet-subtitle">{character.role}</div>
          </div>
        </div>
        <div className="character-sheet-body">
          {character.story && (
            <div className="section">
              <b>Missions</b>
              <StoryComponent story={character.story} />
              <StoryComponent
                size="sm"
                story={{
                  rules: [
                    {
                      stars: 1,
                      icons: character.id,
                    },
                  ],
                }}
              />
            </div>
          )}

          <div className="section">
            <b>Faction</b>
            <Faction faction={character.faction} />
          </div>
          <div className="section">
            <b>Actions</b>
            <div className="story-row">
              <GiCardPlay size={15} className="story-star" /> Get a new Card
            </div>
            <div className="story-row">
              <GiUpCard size={15} className="story-star" /> Upgrade a Card
            </div>
            <div className="story-row">
              <GiBuyCard size={15} className="story-star" /> Trade with the
              Mysterious Merchant
            </div>
          </div>
        </div>
      </div>
      <div className="column">
        Diary
        <Diary />
      </div>
    </div>
  );
};

export default Character;
