import './UpgradeWrapper.css';
import Upgrade from '../../types/Upgrade.interface';
import IconContainer from '../IconContainer/IconContainer';
import { getIcons } from '../../utils/getIcons';
import UpgradeLevel from '../UpgradeLevel/UpgradeLevel';

const UpgradeWrapper = ({ upgrade }: { upgrade: Upgrade }) => {
  const icons = upgrade.icons ? getIcons(upgrade.icons) : undefined;

  return (
    <div className="card-upgrade-wrapper">
      {icons &&
        icons.map((icon, index) => (
          <IconContainer
            icon={{
              ...icon,
              size: 'sm',
              color: '#555',
            }}
            key={`upgrade_${icon.id}_${index}`}
          />
        ))}
      <UpgradeLevel level={upgrade.level - 1} />
    </div>
  );
};

export default UpgradeWrapper;
