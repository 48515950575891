import { ChangeEvent } from 'react';
import Filters from '../../types/Filters.interface';
import IconsData from '../../data/icons.json';
import iconNormalizer from '../../utils/iconNormalizer';
import IconInterface from '../../types/Icon.interface';
import Icon from '../Icon/Icon';
import './Settings.css';

const Settings = ({
  filters,
  onChange,
}: {
  filters: Filters;
  onChange: (filters: Filters) => void;
}) => {
  const handleChangeValue =
    (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
      let value: string | number | boolean = e.target.value;
      if (e.target.type === 'checkbox') {
        value = e.target.checked;
      }

      if (e.target.type === 'number') {
        value = parseInt(e.target.value);
      }

      onChange({
        ...filters,
        [name]: value,
      });
    };

  const getIcons = (): IconInterface[] =>
    IconsData.map((icon) => iconNormalizer(icon)).filter(
      (row) => row !== undefined
    ) as IconInterface[];

  const handleClickIcon = (icon: IconInterface) => {
    onChange({
      ...filters,
      icons: filters.icons?.includes(icon.id)
        ? filters.icons.filter((i) => i !== icon.id)
        : [...filters.icons, icon.id],
    });
  };

  return (
    <div className="container bg-emerald-600 p-4 max-w-none my-1.5 text-white">
      <form>
        <label htmlFor="set">Set</label>
        <input
          id="set"
          className="text-black w-40"
          type="number"
          min={0}
          onChange={handleChangeValue('set')}
          placeholder="Min ID"
          value={filters.set || 0}
        />
        <label htmlFor="minId">Min ID</label>
        <input
          id="minId"
          className="text-black w-40"
          type="number"
          min={0}
          onChange={handleChangeValue('idMin')}
          placeholder="Min ID"
          value={filters.idMin || 0}
        />
        <label htmlFor="minId">Max ID</label>
        <input
          id="minId"
          className="text-black w-40"
          type="number"
          min={0}
          onChange={handleChangeValue('idMax')}
          placeholder="Max ID"
          value={filters.idMax || 0}
        />
        <label htmlFor="characterId">Character ID</label>
        <input
          id="characterId"
          className="text-black w-40"
          type="number"
          min={0}
          onChange={handleChangeValue('characterId')}
          placeholder="Character ID"
          value={filters.characterId || 0}
        />
        <label htmlFor="onlyWithUpgrade">Only with upgrade?</label>
        <input
          type="checkbox"
          id="onlyWithUpgrade"
          onChange={handleChangeValue('onlyWithUpgrade')}
        />
        <label htmlFor="onlyWithOrigin">Only with origin?</label>
        <input
          type="checkbox"
          id="onlyWithOrigin"
          onChange={handleChangeValue('onlyWithOrigin')}
        />
        <label htmlFor="onlyWithUpgrade">Only with characters?</label>
        <input
          type="checkbox"
          id="onlyWithCharacters"
          onChange={handleChangeValue('onlyWithCharacters')}
        />
        <div className="settings-icons-wrapper">
          {getIcons().map((icon) => (
            <Icon
              key={`settings-icon-${icon.id}`}
              icon={{
                ...icon,
                color: filters.icons.includes(icon.id) ? 'inactive' : 'active',
                size: 'lg',
              }}
              onClick={handleClickIcon}
            />
          ))}
        </div>
        <label htmlFor="showItems">Items</label>
        <input
          type="checkbox"
          id="showItems"
          onChange={handleChangeValue('showItems')}
          checked={filters.showItems}
        />
        <label htmlFor="showStories">Stories</label>
        <input
          type="checkbox"
          id="showStories"
          onChange={handleChangeValue('showStories')}
          checked={filters.showStories}
        />
      </form>
    </div>
  );
};

export default Settings;
