import { GiBuyCard, GiCardPlay, GiUpCard } from 'react-icons/gi';
import './Diary.css';

const Diary = () => {
  const preRows: Array<string | number> = ['card', 'upgrade', 'trade'];
  const rows = preRows.concat(Array.from(Array(31).keys()).map((r) => r + 1));

  const renderIcon = (icon: string | number) => {
    if (icon === 'card') {
      return <GiCardPlay size={15} className="icon" />;
    }
    if (icon === 'upgrade') {
      return <GiUpCard size={15} className="icon" />;
    }
    if (icon === 'trade') {
      return <GiBuyCard size={15} className="icon" />;
    }
    return icon;
  };

  const renderRow = (row: string | number, key: number) => (
    <tr key={`row_${key}`}>
      <td>{renderIcon(row)}</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );

  return (
    <table className="diary-table">
      <thead>
        <th>Day</th>
        <th>1</th>
        <th>2</th>
        <th>3</th>
        <th>4</th>
        <th>5</th>
      </thead>
      <tbody>{rows.map((row, key) => renderRow(row, key))}</tbody>
    </table>
  );
};

export default Diary;
