import characterNormalizer from '../../utils/characterNormalizer';
import Data from '../../data/characters.json';
import Character from '../Character/Character';
import { Fragment, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

const CharactersWrapper = () => {
  const handlePrint = useReactToPrint({
    content: () => componentRef.current || null,
  });

  const componentRef = useRef(null);
  const characters = Data.map((row) => characterNormalizer(row));
  return (
    <Fragment>
      <button
        onClick={handlePrint}
        className="font-bold py-2 px-4 bg-blue-500 text-white w-full"
      >
        Print
      </button>
      <div className="flex flex-wrap gap-2 m-8" ref={componentRef}>
        {characters.map((character) =>
          character ? (
            <Character
              character={character}
              key={`character_id_${character.id}`}
            />
          ) : null
        )}
      </div>
    </Fragment>
  );
};

export default CharactersWrapper;
