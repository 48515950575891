type RuleType = 'own_symbols' | 'others_symbol' | 'symbols_in_game';

export interface Rule {
  type?: RuleType;
  stars: number;
  icons: string | number | string[];
  max?: number;
}

export interface Story {
  rules: Rule[];
}

export interface CustomStory {
  name: string;
  icons: string[];
}

export function isGeneralStory(story: Story | CustomStory): story is Story {
  return (story as Story).rules !== undefined;
}

export function isCustomStory(
  story: Story | CustomStory
): story is CustomStory {
  return (story as CustomStory).name !== undefined;
}

export type StoryOrCustomStory = Story | CustomStory;
