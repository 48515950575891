import './Origin.css';

const Origin = ({ origin, dark }: { origin?: string; dark?: boolean }) => {
  if (!origin) {
    return null;
  }
  return (
    <span className="card-origin" style={{ color: dark ? '#fff' : '#000' }}>
      {origin}
    </span>
  );
};

export default Origin;
