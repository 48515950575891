import { GiRoundStar } from 'react-icons/gi';
import './Story.css';

const Simple = () => {
  return (
    <div className="story-container">
      <div className="story-row">
        1
        <GiRoundStar color="orange" size={15} className="story-star" />
        for each card with 1 or less perks (max 5)
      </div>
    </div>
  );
};

export default Simple;
