import ambra from './ambra.png';
import animal from './animal.png';
import artifact from './artifact.png';
import beer from './beer.png';
import book from './book.png';
import brush from './brush.png';
import bug from './bug.png';
import fish from './fish.png';
import fist from './fist.png';
import candle from './candle.png';
import cooking from './cooking.png';
import crafted from './crafted.png';
import crate from './barrel.png';
import crucifix from './crucifix.png';
import crystal from './crystal.png';
import cash from './cash.png';
import cloth from './cloth.png';
import death from './death.png';
import eclipse from './eclipse.png';
import electricity from './light-bulb.png';
import fire from './fire.png';
import food from './food.png';
import fossil from './fossil.png';
import gears from './cog.png';
import gem from './gem.png';
import heart from './hearts.png';
import herbs from './herbs.png';
import illusion from './illusion.png';
import inspector from './inspector.png';
import jewelery from './jewelery.png';
import lockpicks from './lockpicks.png';
import magic from './magic.png';
import medicine from './medicine.png';
import monster from './daemon-skull.png';
import music from './music.png';
import navigation from './navigation.png';
import perfume from './perfume.png';
import science from './atom.png';
import sewing from './sewing.png';
import ship from './ship.png';
import star from './star.png';
import telescope from './telescope.png';
import tool from './tool.png';
import toy from './toy.png';
import treasure_map from './treasure-map.png';
import trophy from './trophy.png';
import valuables from './valuables.png';
import weapons from './weapons.png';

export const FishToImage = new Map<string, string>([
  ['ambra', ambra],
  ['animal', animal],
  ['artifact', artifact],
  ['beer', beer],
  ['brush', brush],
  ['book', book],
  ['candle', candle],
  ['cooking', cooking],
  ['crucifix', crucifix],
  ['death', death],
  ['eclipse', eclipse],
  ['electricity', electricity],
  ['fire', fire],
  ['fish', fish],
  ['fossil', fossil],
  ['bug', bug],
  ['cash', cash],
  ['cloth', cloth],
  ['crafted', crafted],
  ['crate', crate],
  ['crystal', crystal],
  ['fist', fist],
  ['food', food],
  ['gem', gem],
  ['heart', heart],
  ['herbs', herbs],
  ['illusion', illusion],
  ['inspector', inspector],
  ['jewelery', jewelery],
  ['lockpicks', lockpicks],
  ['magic', magic],
  ['medicine', medicine],
  ['monster', monster],
  ['music', music],
  ['navigation', navigation],
  ['perfume', perfume],
  ['science', science],
  ['sewing', sewing],
  ['ship', ship],
  ['star', star],
  ['technology', gears],
  ['telescope', telescope],
  ['tool', tool],
  ['toy', toy],
  ['treasure', treasure_map],
  ['trophy', trophy],
  ['valuables', valuables],
  ['weapons', weapons],
]);
