import { GiRoundStar } from 'react-icons/gi';
import IconContainer from '../components/IconContainer/IconContainer';
import './Story.css';
import { Rule, Story } from '../types/Story.interface';

const GeneralStory = ({ story, size }: { story: Story; size?: string }) => {
  const renderDescription = (rule: Rule) => {
    if (rule.type === 'others_symbol') {
      return 'for each character with at least 1';
    }

    if (rule.max === 1) {
      return 'if you have at least 1';
    }

    return 'for each';
  };

  const renderSuffix = (rule: Rule) => {
    if (rule.type === 'symbols_in_game') {
      return 'in game. ';
    }
  };

  const renderIcons = (rule: Rule) => {
    if (Array.isArray(rule.icons)) {
      return rule.icons.map((icon) => (
        <div className="story-icon-wrapper">
          <IconContainer icon={icon} size={size || 'sm'} />
        </div>
      ));
    }
    return (
      <div className="story-icon-wrapper">
        <IconContainer icon={rule.icons} size={size || 'sm'} />
      </div>
    );
  };

  const renderRule = (rule: Rule, key: number) => {
    return (
      <div className="story-row" key={`story_rule_${key}`}>
        {rule.stars}
        <GiRoundStar color="orange" size={15} className="story-star" />{' '}
        {renderDescription(rule)}
        {renderIcons(rule)}
        {renderSuffix(rule)}
        {rule.max && rule.max > 1 && `(max ${rule.max})`}
      </div>
    );
  };

  return (
    <div className="story-container">
      {story.rules.map((rule, key) => renderRule(rule, key))}
    </div>
  );
};

export default GeneralStory;
